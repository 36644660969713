<template>
    <div class="flex flex-col-reverse lg:flex-row relative">
        <div class="lg:w-3/4">
            <h2 id="help"><b>How to Get Help</b></h2>
            <span style="font-weight: 400"
                >If you have a question or need technical support, there are
                several ways to get help:</span
            >
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400">You can call +</span
                    ><span style="font-weight: 400">1 480-618-5121</span>
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >From within the FACT virtual meeting, you can click on
                        the Live Chat icon in the bottom right corner and start
                        a live chat session</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Live support via phone and chat are available these
                        hours:</span
                    >
                    <ul>
                        <li aria-level="2">
                            <span style="font-weight: 400">SUPPORT DAYS/TIMES:</span>
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Monday, February 1 - 1:00 - 3:00 CST/2:00 - 4:00pm EST</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Tuesday, February 2 - 9:00 - 11:00 CST/10:00am - 12:00pm EST</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Wednesday, February 3 - 9:00 - 3:00 CST/10am - 4pm EST</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Thursday, 2/4: 7:15 a.m - 4:00 p.m. CST/8:15 a.m. - 5:00 p.m. EST</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Friday, 2/5:  7:15 a.m - 4:00 p.m. CST/8:15 a.m. - 5:00 p.m. EST</span
                            >
                        </li>
                    </ul>
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >The technical support line and live chat will be
                        staffed to assist with logins, meeting access, and
                        technical challenges.</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >You can access our virtual Help Desk. Here you can be
                        on video with someone who can help you with your tech
                        setup, answer questions about the bootcamp, and more.
                    </span>
                </li>
                <li aria-level="1">
                    <b
                        >To visit the Help Desk,
                        <router-link to="/sessions"
                            >check the schedule</router-link
                        >
                        and join today's Help Desk meeting room.</b
                    >
                </li>
            </ul>
            <h2 id="faq"><b>Frequently Asked Questions</b></h2>
            <h3><b>Logging in</b></h3>
            <span style="font-weight: 400"
                >Since you are accessing these FAQs from within the bootcamp, we
                assume you were able to log in successfully.</span
            >
            <ul>
                <li aria-level="1">
                    <b
                        >Can I access the platform before the event begins to
                        confirm my equipment will work?</b
                    ><b> </b
                    ><span style="font-weight: 400"
                        >Yes, attendees will be given access to the virtual
                        event platform beginning Monday, February 1, 2021. Visit
                        the </span
                    ><router-link to="/sessions">“SCHEDULE” tab</router-link
                    ><span style="font-weight: 400"> to access.</span>
                </li>
            </ul>
            &nbsp;
            <h2><b>My Attendee Profile</b></h2>
            <span style="font-weight: 400"
                >As an attendee, you can update your individual profile,
                including a photo, bio and other information.</span
            >
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Navigate to the
                        <router-link to="/attendees"
                            >Attendees tab</router-link
                        ></span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Click “View My Profile”</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400">You will be able to:</span>
                    <ul>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Add an avatar image 600x600 square (.png or
                                .jpg)</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Edit your profile, including:</span
                            >
                            <ul>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Email (You decide if you want this to
                                        display, by checking the “display email”
                                        box. It is NOT displayed by
                                        default)</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400">Title</span>
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Phone (and decide if you want this to
                                        display, by checking the “display Phone
                                        number” box. It is NOT displayed by
                                        default)</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Add your social media account links;
                                        these will show up as icons on your
                                        profile</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Add a short bio</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Add info about your organization</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Things you cannot change:</span
                    >
                    <ul>
                        <li aria-level="2">
                            <span style="font-weight: 400">Your name</span>
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >The name of your organization</span
                            >
                        </li>
                    </ul>
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >If you need to change either of these, please contact
                        FACT.</span
                    >
                </li>
            </ul>
            <h2 id="troubleshooting"><b>Troubleshooting</b></h2>
            <span style="font-weight: 400"
                >How do I get help if I am having technical issues?</span
            >
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >There are several ways to get help if you are having
                        issues with the site.</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Click on the “HELP” tab on the top menu bar (this
                        page).</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Click on the help icon on the bottom right corner or
                        your screen to chat live.</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400">Call: (480) 618-5121..</span>
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Live technical support is available the days of the
                        conference. See schedule above</span
                    >
                </li>
            </ul>
            <h2 id="communicating">Communicating with other participants</h2>
            <b>Where can I see who is attending?</b>

            <span style="font-weight: 400"
                >Click on the
                <router-link to="/attendees">‘ATTENDEES’</router-link> tab on
                the top menu bar. You can search for participants by first name,
                last name, organization or state.</span
            >
            <h2><b>Can I contact or message other participants?</b></h2>
            <span style="font-weight: 400"
                >Yes, you can search for a participant by first name, last name,
                or institution under the
                <router-link to="/attendees">‘ATTENDEES’</router-link> tab on
                the top menu bar. Then click on the ‘Send a Message’ icon.</span
            >
            <h2 id="meetings"><b>Private Meetings/Appointments</b></h2>
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >The
                        <router-link to="/attendees"
                            >‘ATTENDEES’ tab</router-link
                        >
                        lets you search for attendees so you can schedule a
                        meeting..</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >You can search for other attendees by first name, last
                        name, and organization</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Once you find the person you are looking for, click
                        schedule a meeting</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >You will be able to invite up to 3 other people for
                        each appointment</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Appointments are available on your
                        <router-link to="/schedule"
                            >‘My Schedule’ page</router-link
                        >
                        and will be held in a video room</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >You may schedule appointments </span
                    ><b>ONLY for February 3, 2021</b
                    ><span style="font-weight: 400">
                        so as not to conflict with the sessions.</span
                    >
                </li>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >After you create the invitation, the people invited
                        will</span
                    >
                    <ul>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >See a message in the message center (if they
                                are not on the page, they will see a small
                                number next to the Message Center tab showing
                                that there is a message)</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >See the invitation on their
                                <router-link to="/schedule"
                                    >‘My Schedule’ page</router-link
                                >.</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Invitees can accept or decline (on My
                                Schedule); you will receive a message/email with
                                the acceptance message or the decline.</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >Invitees can send you a follow up message,
                                asking to move the time or for you to add people
                                to the meeting invitation</span
                            >
                        </li>
                        <li aria-level="2">
                            <span style="font-weight: 400"
                                >As the host, you can</span
                            >
                            <ul>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Edit the meeting invitation
                                        date/time</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Add additional invitees (up to the
                                        limit)</span
                                    >
                                </li>
                                <li aria-level="3">
                                    <span style="font-weight: 400"
                                        >Cancel the meeting invitation</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <h2><b>Are the sessions being recorded?</b></h2>
            <span style="font-weight: 400"
                >Yes. All live sessions in the program will be recorded. They
                will be available in the ‘On-demand’ section of the Program
                within a day of their broadcast.</span
            >
            <h2 id="best-practices">
                <b>Best Practices For Meeting Participation</b>
            </h2>
            <h3><b>General requirements</b></h3>
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >You will need access to either a PC, Mac, tablet, or
                        smartphone. A PC or Mac is recommended. To participate
                        in the meetings, it is recommended that you have an
                        internal or external camera so that you can be seen on
                        video.</span
                    >
                </li>
            </ul>
            <h3><b>Microphones</b></h3>
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >Clear audio can make a big difference. Laptop/phone
                        audio can work, but audio through an external microphone
                        (connected by wire or Bluetooth) can be a huge plus. We
                        recommend:</span
                    >
                    <ul>
                        <li aria-level="2">
                            <a
                                href="https://www.amazon.com/Logitech-Headset-H390-Noise-Cancelling/dp/B000UXZQ42/ref=psdc_3015405011_t3_B00L2JUJ68"
                                ><b
                                    >Logitech USB Headset H390 with Noise
                                    Cancelling Mic</b
                                ></a
                            >
                        </li>
                        <li aria-level="2">
                            <a
                                href="https://www.amazon.com/Education-1EDU-AE36WH-ITE-Headphone-Microphone-Single/dp/B00L2JUJ68/ref=sr_1_2?dchild=1&amp;keywords=Avid+Education+1EDU-AE36WH-+ITE+Headphone+with+Boom+Microphone%2C&amp;qid=1590542817&amp;sr=8-2"
                                ><b
                                    >Avid Education 1EDU-AE36WH-ITE Headphone
                                    with Boom Microphone, Single Plug, White</b
                                ></a
                            >
                        </li>
                    </ul>
                </li>
            </ul>
            <h3><b>Browser</b></h3>
            <span style="font-weight: 400"
                >The FACT bootcamp will support the latest versions of Chrome,
                Safari, Firefox, and Edge. Internet Explorer 11 is not fully
                supported and you should use a different browser.</span
            >
            <ul>
                <li aria-level="1">
                    <span style="font-weight: 400"
                        >If you plan to participate in the FACT bootcamp from a
                        tablet or smartphone, know that some features will be
                        harder to access, although watching sessions will work
                        just fine.</span
                    >
                </li>
            </ul>
            <h2><b>Accessing Private Meetings</b></h2>
            <ul>
                <li aria-level="2">
                    <span style="font-weight: 400"
                        >There's a lot of tech going on. Please make sure you
                        can access the private meeting rooms by visiting the
                        Help Desk. While there:</span
                    >
                    <ol>
                        <li aria-level="3">
                            <span style="font-weight: 400"
                                >Make sure you can see other people on
                                video.</span
                            >
                        </li>
                        <li aria-level="3">
                            <span style="font-weight: 400"
                                >Make sure you have given your browser
                                permission to use your speaker and
                                microphone.</span
                            >
                        </li>
                    </ol>
                </li>
            </ul>
            &nbsp;
            <h3>
                <b
                    >We are here to help you get the most out of the bootcamp,
                    so please be in touch! Have a wonderful time!</b
                >
            </h3>
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <ul>
                <!-- IN-PAGE NAV -->
                <li><a href="#help">How to Get Help</a></li>
                <li><a href="#faq">Frequently Asked Questions</a></li>
                <li><a href="#troubleshooting">Troubleshooting</a></li>
                <li>
                    <a href="#communicating"
                        >Communicating with other participants</a
                    >
                </li>
                <li><a href="#meetings">Private Meetings/Appointments</a></li>
                <li>
                    <a href="#best-practices"
                        >Best Practices for Meeting Participation</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "helpFactBc"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
